import HeaderComponent from './Header'
import Main from './Main'
import FooterComponent from './Footer'

const Homepage = ({setError, setErrMsg}) => {
  return (
    <div>
      <div className='divsoverlay'>
      <HeaderComponent />
      <Main setError={setError} setErrMsg={setErrMsg} />)
      <FooterComponent />
    </div>
    </div>
  );
}

export default Homepage