import { useEffect, useState, useContext } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import classNames from "classnames"
import useFetchNFT from "../../hooks/useFetchNFT"
import { ethers } from "ethers"
import CONFIG from './../../abi/config.json'
import contractABI from './../../abi/abi.json'
import nftAbi from './../../abi/nft.json'
import useStakedNFT from "../../hooks/useStakedNFT"
import LoadingComponent from "../../components/LoadingComponent"

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import useNFTInfo from "../../hooks/useNFTInfo"
import useFetchLockData from "../../hooks/useFetchLockData"

const MySwal = withReactContent(Swal)

const style1 = {
    WebkitTransform: "translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)",
    MozTransform: "translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)",
    msTransform: "translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)",
    transform: "translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)",
    opacity: 0
}

const style2 = {
    WebkitTransform: "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0deg) rotateZ(0) skew(0, 0)",
    MozTransform: "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0deg) rotateZ(0) skew(0, 0)",
    msTransform: "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0deg) rotateZ(0) skew(0, 0)",
    transform: "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0deg) rotateZ(0) skew(0, 0)",
    transformStyle: "preserve-3d",
    opacity: 1
}

const style3 = {
    WebkitTransform: "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0deg) rotateZ(0) skew(0, 0)",
    MozTransform: "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0deg) rotateZ(0) skew(0, 0)",
    msTransform: "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0deg) rotateZ(0) skew(0, 0)",
    transform: "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0deg) rotateZ(0) skew(0, 0)",
    transformStyle: "preserve-3d",
    opacity: 0
}

const Stake = () => {
    const { account, web3Provider } = useContext(GlobalContext)
    const [fetchNFTs, setFetchNfts] = useState(true)
    const [totalReward, setTotalReward] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const [loading, setLoading] = useState(false)
    const [selectedNFTs, setSelectedNFTs] = useState([])
    const [selectedStNFTs, setSelectedStNFTs] = useState([])

    const lockdata = useFetchLockData();
    const nfts = useFetchNFT(web3Provider, account, fetchNFTs, setFetchNfts)
    const stNfts = useStakedNFT(web3Provider, account, fetchNFTs, setFetchNfts)
    const nftInfos = useNFTInfo(web3Provider, stNfts, lockdata, selectedStNFTs, setTotalReward);


    const [showdetails, setShowDetails] = useState([]);

    console.log(nftInfos);
    const checkBoxClick = (e, tokenId) => {
        console.log(parseInt(tokenId, 16))
        const imageDisplay = e.target.querySelector('img').style.display;
        if (imageDisplay !== "none") {
            e.target.querySelector('img').style.display = "none"
            setSelectedNFTs([...selectedNFTs.filter(nft => nft !== parseInt(tokenId, 16))])
        } else {
            e.target.querySelector('img').style.display = "block"
            setSelectedNFTs([...selectedNFTs, parseInt(tokenId, 16)])
        }
    }


    const checkBoxClickStaked = (e, tokenId) => {
        const imageDisplay = e.target.querySelector('img').style.display;
        if (imageDisplay !== "none") {
            e.target.querySelector('img').style.display = "none"
            setSelectedStNFTs([...selectedStNFTs.filter(nft => nft !== tokenId)])
        } else {
            e.target.querySelector('img').style.display = "block"
            setSelectedStNFTs([...selectedStNFTs, tokenId])
        }
    }

    const clearCheckboxes = () => {
        Array.from(document.querySelector('.stake-nfts').querySelectorAll('img.image-tick')).map(item => {
            item.style.display = "none"
        })
        Array.from(document.querySelector('.staked').querySelectorAll('img.image-tick')).map(item => {
            item.style.display = "none"
        })
    }

    const checkApproval = async () => {
        try {
            setLoading(true)
            const signer = web3Provider.getSigner()
            const nftContract = new ethers.Contract(CONFIG.NFT_CONTRACT, nftAbi, signer)
            const isApproval = await nftContract.isApprovedForAll(account, CONFIG.STAKING_CONTRACT_ADDRESS)
            if (isApproval) {
                stakeNfts()
            } else {
                approveNFT()
            }
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    const approveNFT = async () => {
        try {
            setLoading(true)
            if (selectedNFTs.length === 0) {
                MySwal.fire({
                    position: 'top-end',
                    title: 'Error!',
                    text: 'No nft selected to stake',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500
                })
                // alert('no nft selected to stake')
                setLoading(false)
                return;
            }
            const signer = web3Provider.getSigner()
            const nftContract = new ethers.Contract(CONFIG.NFT_CONTRACT, nftAbi, signer)
            const estimateGas = await nftContract.estimateGas.setApprovalForAll(CONFIG.STAKING_CONTRACT_ADDRESS, true)
            console.log(estimateGas.toString())
            const tx = {
                gasLimit: estimateGas.toString()
            }

            const approveTx = await nftContract.setApprovalForAll(CONFIG.STAKING_CONTRACT_ADDRESS, true, tx)
            await approveTx.wait()
            stakeNfts()

        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    const stakeNfts = async () => {
        try {
            setLoading(true)
            console.log(selectedNFTs)
            if (selectedNFTs.length === 0) {
                MySwal.fire({
                    position: 'top-end',
                    title: 'Error!',
                    text: 'No nft selected to stake',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500
                })
                // alert('no nft selected to stake')
                setLoading(false)
                return;
            }
            const signer = web3Provider.getSigner()
            console.log(selectedNFTs, selectedPeriod);
            const stakingContract = new ethers.Contract(CONFIG.STAKING_CONTRACT_ADDRESS, contractABI, signer)
            const estimateGas = await stakingContract.estimateGas.stake(selectedNFTs, selectedPeriod)
            console.log(estimateGas.toString())
            const tx = {
                gasLimit: estimateGas.toString()
            }
            const stakingTx = await stakingContract.stake(selectedNFTs, selectedPeriod, tx)
            await stakingTx.wait()
            setFetchNfts(true)
            setLoading(false)
            MySwal.fire({
                position: 'top-end',
                title: 'success',
                text: 'Staking done',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })
            // alert('staking done')
            setSelectedNFTs([])
            clearCheckboxes()

        } catch (e) {
            setLoading(false)
            setSelectedNFTs([])
            clearCheckboxes()
            console.log(e)
        }

    }

    const unStake = async () => {
        try {
            setLoading(true)
            console.log(selectedStNFTs)
            if (selectedStNFTs.length === 0) {
                MySwal.fire({
                    position: 'top-end',
                    title: 'Error!',
                    text: 'No nft selected to unstake',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500
                })
                // alert('no nft selected to unstake')
                setLoading(false)
                return;
            }
            const signer = web3Provider.getSigner()
            const stakingContract = new ethers.Contract(CONFIG.STAKING_CONTRACT_ADDRESS, contractABI, signer)
            const estimateGas = await stakingContract.estimateGas._unstakeMany(selectedStNFTs)
            console.log(estimateGas.toString())
            const tx = {
                gasLimit: estimateGas.toString()
            }
            const stakingTx = await stakingContract._unstakeMany(selectedStNFTs, tx)
            await stakingTx.wait()
            setFetchNfts(true)
            setLoading(false)
            MySwal.fire({
                position: 'top-end',
                title: 'success',
                text: 'Unstaking done',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })
            // alert('staking done')
            setSelectedStNFTs([])
            clearCheckboxes()

        } catch (e) {
            setLoading(false)
            setSelectedStNFTs([])
            clearCheckboxes()
            console.log(e)
        }
    }


    return (
        <>
            {loading && (<LoadingComponent />)}
            <section>
            <div className="v-application v-theme--imaginaryWorldTheme v-layout v-layout--full-height v-locale--is-ltr is-landing-page">
<div className='v-application__wrap'>
<main className="v-main">
<div className="v-main__wrap">
  <div className="ball-background">
  <div className="ball ball--1"></div>
  <div className="ball ball--2"></div>
  <div className="ball ball--3"></div>
  <div className="ball ball--4"></div>
  <div className="ball ball--5"></div>
  <div className="v-responsive v-img ball ball--blue">
  <div className="v-responsive__sizer" style={{ paddingBottom: "100%"}}></div>
  <img className="v-img__img v-img__img--contain" src="/images/ball-1.50ee057a.png"/></div>
  <div className="v-responsive v-img ball ball--green">
  <div className="v-responsive__sizer" style={{ paddingBottom: "100%"}}></div>
  <img className="v-img__img v-img__img--contain" src="/images/ball-1.50ee057a.png"/></div>
<div className="v-responsive v-img ball ball--purple"><div className="v-responsive__sizer" style={{ paddingBottom: "100%"}}></div>
<img className="v-img__img v-img__img--contain" src="/images/ball-1.50ee057a.png"/></div>
</div>


<div className="sidebar is-hidden">

<button type="button" class="v-btn v-btn--elevated v-btn--icon v-theme--imaginaryWorldTheme v-btn--density-default v-btn--size-default v-btn--variant-elevated sidebar__toggle">
<span class="v-btn__overlay"></span>
<span class="v-btn__underlay"></span>
<span class="v-btn__content" data-no-activator="">
<i class="icon-sidebar mdi v-icon notranslate v-theme--imaginaryWorldTheme v-icon--size-default" aria-hidden="true"></i></span>
</button>


<div className="sidebar__wrapper rounded-2xl">
<header className="sidebar__header">
<a href="https://imaginaryones.com/" target="_blank" className="sidebar__logo"></a>
<button type="button" className="v-btn v-btn--elevated v-btn--icon v-theme--imaginaryWorldTheme v-btn--density-default v-btn--size-default v-btn--variant-elevated sidebar__close rounded-2xl d-md-none">
<span className="v-btn__overlay"></span>
<span className="v-btn__underlay"></span>
<span className="v-btn__content" data-no-activator="">
<i className="mdi-close mdi v-icon notranslate v-theme--imaginaryWorldTheme v-icon--size-default" aria-hidden="true"></i></span>
</button>
</header>

<main className="sidebar__main">
<nav className="sidebar__navigation">
<ul className="navigation__list">
<li className="navigation__item">
<a href="/dashboard" className="router-link-active router-link-exact-active navigation__link" aria-current="page"><span>Dashboard</span></a>
</li>

<li className="navigation__item">
<a href="/staking" className="navigation__link staking-page-active"><span>Staking</span></a>
</li>

<li className="navigation__item"><span className="navigation__link navigation__link--coming-soon">
<span>NFT Bridge</span>
<span className="coming-soon">Coming Soon</span></span></li>




<li className="navigation__item">
<a href="/rewards" className="navigation__link">
<span>Mint NFT</span>
</a></li>


<li className="navigation__item">
<a href="/rewards" className="navigation__link">
<span>Rewards</span>
</a></li>

</ul>
</nav>
</main>






<footer className="sidebar__footer">
<div className="d-flex">
<div className="v-responsive v-img footer__image flex-grow-0">
<div className="v-responsive__sizer" style={{paddingBottom: "100%"}}></div>
<img className="v-img__img v-img__img--contain footer-logo-2" src="/images/bunny-punk-logo.svg"/>
</div>
<div className="footer__content flex-grow-1">
<div className="footer__name"> </div></div>
<button type="button" className="v-btn v-btn--icon v-theme--imaginaryWorldTheme v-btn--density-default v-btn--size-default v-btn--variant-text footer__button" aria-haspopup="menu" aria-expanded="false" aria-owns="menu">
<span className="v-btn__overlay"></span>
<span className="v-btn__underlay"></span>
<span className="v-btn__content" data-no-activator="">
<i className="icon icon-three-dots mdi v-icon notranslate v-theme--imaginaryWorldTheme v-icon--size-default" aria-hidden="true"></i></span>
</button>
</div>
</footer>










</div>
</div>








<main className="main is-unconnected is-open-to-public">
<div className="v-container">
<div className="landing top-margins-landing">
<div className="landing__ball landing__ball--1" tabindex="-1">
<img className="ball" src="/images/ball-1.50ee057a.png"/></div>
  <div className="landing__ball landing__ball--2" tabindex="-1">
<img className="ball" src="/images/ball-2.30e35285.png"/></div>

                
                
                
                
<div className="banner__text"><h1 className="banner__title">
<span className="t--white t-aligned-c">STAKE AND EARN</span></h1>
</div>

                <div data-w-id="584c57f4-5178-0bf2-1a84-17853597b257" style={{ opacity: 0 }} className="tabs-holder">
                    <div data-w-id="002cffd5-e811-2ac9-941c-bdffec8f43b8" className="stake-tabs mynfts">my nfts</div>
                    <div data-w-id="d91d0851-0831-1435-1930-c2284add1765" className="stake-tabs stakes-tab">staked</div>
                    </div>
                    

                    <div className="stake-nfts">
                    {nfts && nfts.ownedNfts.map((nft, i) => {
                        if (i < nfts.ownedNfts.length - 1) {
                            return (
                                <div key={i} className="nft-row">
                                    <div className="div-row-fit">
                                    <div className="check-box-holder" onClick={e => checkBoxClick(e, nft.id.tokenId)}>
                                        <img src="images/tick.svg" style={{ pointerEvents: "none", display: "none" }} loading="lazy" alt="" className="image-tick" />
                                    </div>
                                    <div className="nft-image">
                                        <img className="bored-lines" src={nft.media[0].gateway} loading="lazy" style={style2} alt="" className="image-nft-stake" />
                                    </div>
                                    <div className="nft-id">{nft.title}</div>
                                    </div>
                                    <div className="white-detail-line"></div>
                                </div>
                            )
                        } else {
                            return (
                                <div key={i} className="nft-row last">
                                    <div className="div-row-fit">
                                    <div className="check-box-holder" onClick={e => checkBoxClick(e, nft.id.tokenId)}>
                                        <img src="images/tick.svg" style={{ pointerEvents: "none", display: "none" }} loading="lazy" alt="" className="image-tick" />
                                    </div>
                                    <div className="nft-image">
                                        <img className="bored-lines" src={nft.media[0].gateway} loading="lazy" width="76" alt="" />
                                    </div>
                                    <div className="nft-id">{nft.title}</div>
                                    </div>
                                    <div className="white-detail-line"></div>
                                    <div className="white-detail-line bottom"></div>
                                </div>
                            )
                        }
                    })}
                    {
                        !nfts && (
                            <div className="nft-row last">
                                <p className="tw-text-white">Hey there! It looks like you have no BP yet</p>
                            </div>
                        )
                    }
                </div>
                <div className="staked">
                    {stNfts && (
                        stNfts.map((nft, i) => {
                            if (i < stNfts.length - 1) {
                                return (
                                    <div className="nft-row">
                                        <div key={i} className={'nft-row-panel'}>

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="check-box-holder" onClick={e => checkBoxClickStaked(e, nft)}><img src="images/tick.svg" style={{ pointerEvents: "none", display: "none" }} loading="lazy" alt="" className="image-tick" /></div>
                                                <div className="nft-image"><img className="bored-lines" src="images/bunny-carrot.png" loading="lazy" width="55" alt="" className="image-16" /></div>
                                                <div className="nft-id">Bunny #{nft.toString()}</div>
                                                {/* <div className="staked-info">
                                            <div className="staked-for">STAKED FOR 30 days</div>
                                            <div className="earning">earning 5%</div>
                                        </div> */}
                                                <div className="white-detail-line"></div>
                                            </div>
                                            <div style={{ color: 'white', fontFamily: 'Chakra Petch', cursor: 'pointer' }}
                                                onClick={() => {
                                                    let temp = [...showdetails];
                                                    temp[i] = !temp[i];
                                                    setShowDetails(temp);
                                                }}>Details</div>
                                        </div>
                                        <div  style={{ fontFamily: 'Chakra Petch', color: 'white', width: '100%', display: showdetails[i] ? 'block' : 'none', fontSize: '14px', lineHeight: '28px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                Lock Days : {nftInfos && nftInfos[i] ? nftInfos[i].periodValue : 0} days
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                APR : {nftInfos && nftInfos[i] ? Number(nftInfos[i].apr).toFixed(2) : 0}%
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                Total Reward : {nftInfos && nftInfos[i] ? Number(nftInfos[i].reward).toFixed(4) : 0} Bunny
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '-5px', fontSize: '12px', fontWeight: '600', color: nftInfos && nftInfos[i] && nftInfos[i].withdrawable ? '#00c851' : '#ff4444' }}>
                                                {nftInfos && nftInfos[i] && nftInfos[i].withdrawable ? 'Lock days is expired' : 'Lock days is not expired'}
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="nft-row last">
                                        <div key={i} className={'nft-row-panel'}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="check-box-holder" onClick={e => checkBoxClickStaked(e, nft)}><img src="images/tick.svg" style={{ pointerEvents: "none", display: "none" }} loading="lazy" alt="" className="image-tick" /></div>
                                                <div className="nft-image"><img className="bored-lines" src="images/bunny-carrot.png" loading="lazy" width="55" alt="" /></div>
                                                <div className="nft-id">Bunny #{nft.toString()}</div>
                                                {/* <div className="staked-info">
                                            <div className="staked-for">STAKED FOR 30 days</div>
                                            <div className="earning">earning 5%</div>
                                        </div> */}
                                                <div className="white-detail-line"></div>
                                                <div className="white-detail-line bottom"></div>
                                            </div>
                                            <div className="custom-paddings-onbtn" style={{cursor: ' pointer'}}
                                                onClick={() => {
                                                    let temp = [...showdetails];
                                                    temp[i] = !temp[i];
                                                    setShowDetails(temp);
                                                }}>DETAILS</div>
                                        </div>
                                        <div style={{ fontFamily: 'Chakra Petch', color: 'white', width: '100%', display: showdetails[i] ? 'block' : 'none', fontSize: '14px', lineHeight: '28px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                Lock Days : {nftInfos && nftInfos[i] ? nftInfos[i].periodValue : 0} days
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                APR : {nftInfos && nftInfos[i] ? Number(nftInfos[i].apr).toFixed(2) : 0}%
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                Total Reward : {nftInfos && nftInfos[i] ? Number(nftInfos[i].reward).toFixed(4) : 0} Bunny
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '-5px', fontSize: '12px', fontWeight: '600', color: nftInfos && nftInfos[i] && nftInfos[i].withdrawable ? '#00c851' : '#ff4444' }}>
                                                {nftInfos && nftInfos[i] && nftInfos[i].withdrawable ? 'Lock days is expired' : 'Lock days is not expired'}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    )}
                    {
                        stNfts.length === 0 && (
                            <div className="nft-row">
                                <p className="tw-text-white tw-mt-4">You have no BPs staked</p>
                            </div>
                        )
                    }

                    {
                        stNfts.length > 0 ? <div style={{ display: 'flex', justifyContent: 'end', color: 'white', width: '100%', marginTop: '20px' }}>
                            Total Earnings : {Number(totalReward).toFixed(4)} Bunny
                        </div> : ''
                    }
                </div>

                    
                    </div>
                </div>
                </main>
            </div>
            </main>
            </div></div>
            
            <div className="stake-period-holder mobile">
                <div className="hero-line left"></div>
                <div className="typo-stake-period">choose staking period in days</div>
                <div className="button-days-holder">
                    <div className={classNames('button-nav-small days', { 'active': selectedPeriod == 0 })} onClick={e => setSelectedPeriod(0)}>
                        <div className="typo-days">{lockdata ? lockdata[0][0] / 1 : 0}</div>
                    </div>
                    <div className={classNames('button-nav-small days', { 'active': selectedPeriod == 1 })} onClick={e => setSelectedPeriod(1)}>
                        <div className="typo-days">{lockdata ? lockdata[1][0] / 1 : 0}</div>
                    </div>
                    <div className={classNames('button-nav-small days', { 'active': selectedPeriod == 2 })} onClick={e => setSelectedPeriod(2)}>
                        <div className="typo-days">{lockdata ? lockdata[2][0] / 1 : 0}</div>
                    </div>
                    <div className={classNames('button-nav-small days', { 'active': selectedPeriod == 3 })} onClick={e => setSelectedPeriod(3)}>
                        <div className="typo-days">{lockdata ? lockdata[3][0] / 1 : 0}</div>
                    </div>
                </div>
                
                <a data-w-id="e7892a29-86dd-8960-fe68-2193ff2d4865" style={{ opacity: 0 }} href="#" className="button-stake v-btn v-btn--elevated v-theme--imaginaryWorldTheme v-btn--density-default elevation-0 v-btn--rounded v-btn--size-default v-btn--variant-elevated btn-connect btn-purple-gradient" onClick={checkApproval}>Stake</a>
                <a data-w-id="d1f62f78-ab19-2899-d55d-9a0983a461a4" style={{ opacity: 0 }} href="#" className="button-unstake" onClick={unStake}>unstake</a>
                <div className="hero-line"></div>
            </div>
            
          





            </section>















            <footer data-w-id="1c6363c1-60a3-5681-8671-51603099bdca" style={{ opacity: 0 }} className="footer stake wf-section">
                <div className="stake-period-holder">
                    <div className="hero-line left"></div>
                    <div className="typo-stake-period">choose staking period in days</div>
                    <div className="button-days-holder">
                        <div className={classNames('button-nav-small days', { 'active': selectedPeriod == 0 })} onClick={e => setSelectedPeriod(0)}>
                            <div className="typo-days">{lockdata ? lockdata[0][0] / 1 : 0}</div>
                        </div>
                        <div className={classNames('button-nav-small days', { 'active': selectedPeriod == 1 })} onClick={e => setSelectedPeriod(1)}>
                            <div className="typo-days">{lockdata ? lockdata[1][0] / 1 : 0}</div>
                        </div>
                        <div className={classNames('button-nav-small days', { 'active': selectedPeriod == 2 })} onClick={e => setSelectedPeriod(2)}>
                            <div className="typo-days">{lockdata ? lockdata[2][0] / 1 : 0}</div>
                        </div>
                        <div className={classNames('button-nav-small days', { 'active': selectedPeriod == 3 })} onClick={e => setSelectedPeriod(3)}>
                            <div className="typo-days">{lockdata ? lockdata[3][0] / 1 : 0}</div>
                        </div>
                    </div>
                    <a data-w-id="e2b7a820-35a6-06f6-ec66-f0b4a902d6fc" style={{ opacity: 0 }} href="#" className="button-stake v-btn v-btn--elevated v-theme--imaginaryWorldTheme v-btn--density-default elevation-0 v-btn--rounded v-btn--size-default v-btn--variant-elevated btn-connect btn-purple-gradient" onClick={checkApproval}>Stake</a>
                    <a data-w-id="a320d48d-3cfa-a880-0e58-e1b68e7768dc" style={{ opacity: 0 }} href="#" className="button-unstake w-button" onClick={unStake}>unstake</a>
                    <div className="hero-line"></div>
                </div>
                <div className="container footer-mobile w-container">
                    <div className="footer-madeby mobile">
                        <p className="typo-footer dark">DAPP BY</p>
                        <a href="http://www.bunnypunk.io/" className="typo-footer footer-link">Bunny Punk</a>
                        <p className="typo-footer dark">POWERED BY</p>
                        <a href="https://www.elastos.info" className="typo-footer footer-link">ELASTOS</a>
                    </div>
                    <div className="footer-links">
                        <div className="footer-link-holder">
                        
                        </div>
                    </div>
                    <div className="footer-madeby">
                        <p className="typo-footer dark">DAPP BY</p>
                        <a href="http://www.bunnypunk.io/" target="_blank" className="footer-link typo-footer">Bunny Punk</a>
                        <p className="typo-footer dark">POWERED BY</p>
                        <a href="https://www.elastos.info" className="typo-footer footer-link">ELASTOS</a>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Stake