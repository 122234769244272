
const HeaderComponent = () => {
    return (
        <>
            <div style={{display: "none"}} className="nav-overlay main is-unconnected is-open-to-public staking-unconnected">
            <link rel="stylesheet" href="https://use.typekit.net/lca0jil.css"/>
            <link rel="stylesheet" href="/src/dashboard.css"/>
                <div className="nav-menu-wrapper">
                    <div className="mobile-link-holder">
                        <a href="https://bunnypunk.io/" className="link-mobile-nav">Home</a>
                        <a href="https://farm.bunnypunk.io/" target="_blank" className="link-mobile-nav mid">Farm</a>
                        <a href="#" target="_blank" className="link-mobile-nav mid nft-soon">NFT Bridge</a>
                        <a href="https://og.bunnypunk.io" target="_blank" className="link-mobile-nav">OG stake</a>
                    </div>
                </div>
                <div className="background"></div>
            </div>
            <div className="nav-mobile">
                <a href="https://bunnypunk.io/" className="logo-link w-inline-block"><img src="images/bunny-punk-logo.svg" loading="lazy" alt="bunny punk logo" /></a>
                <div data-w-id="1a9ec6a6-1440-abf2-b71b-16a1f2030188" className="hamburger-menu"><img src="images/image-X.svg" loading="lazy" alt="" className="image-15" /><img src="images/image---Menu-icon.svg" loading="lazy" alt="" className="image-14" /></div>
            </div>
            <div data-w-id="370d074e-fda9-5545-cdd5-a2b93f2b0593" data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
                <div className="container nav w-container">
                    <a href="https://bunnypunk.io/" className="brand w-nav-brand"><img src="images/bunny-punk-logo.svg" loading="lazy" width="115" alt="bunny punk logo" /></a>
                    <div className="nav-left-holder">
                        <a href="https://glidefinance.io/info/token/0x75740fc7058da148752ef8a9adfb73966deb42a8" target="_blank" className="button-nav-big w-inline-block">
                            <div className="typo-buysun">$BUNNY</div>
                        </a>
                        <a href="https://discord.gg/g4sASzxs" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/discord.png" loading="lazy" alt="discord favicon" className="image-social" /></a>
                        <a href="https://t.me/bunnypunkchat" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/telegram.png" loading="lazy" alt="telegram favicon" className="image-social" /></a>
                        <a href="https://twitter.com/bunny_punk_nft" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/Twitter.svg" loading="lazy" alt="twitter favicon" className="image-social" /></a>
                    </div>
                    <div className="nav-middle-holder">
                        <a href="https://bunnypunk.io/" className="nav-links w-nav-link">Home</a>
                        <a href="https://farm.bunnypunk.io/" target="_blank" className="nav-links w-nav-link">Farm</a>
                        <a href="#" target="_blank" className="nav-links w-nav-link nft-soon">NFT Bridge</a>
                        <a href="https://og.bunnypunk.io/" target="_blank" className="nav-links w-nav-link">OG stake</a>

                    </div>
                    <div data-w-id="fe9c1f3a-ebc1-ca78-0602-5fa6255c14d5" className="menu-button w-nav-button"><img src="images/image---Menu-icon.svg" loading="lazy" data-w-id="59742d16-8afc-3b10-c2a5-ee5bb3b7cbbe" alt="" /><img src="images/image-X.svg" loading="lazy" data-w-id="0f1106b2-dca2-fea8-e825-31c3241a1873" alt="" className="image-13" /></div>
                    <nav role="navigation" className="nav-menu w-nav-menu">
                        <div className="div-block-9">
                            <a href="#" className="button-nav-big-mobile w-inline-block">
                                <div className="typo-buysun">$BUNNY</div>
                            </a>
                            <div className="nav-link-holder-mobile">
                                <a href="https://bunnypunk.io/" className="nav-links w-nav-link">Home</a>
                                <a href="https://farm.bunnypunk.io/" target="_blank" className="nav-links w-nav-link">Farm</a>
                                <a href="#" className="nav-links w-nav-link nft-soon">NFT Bridge</a>
                                <a href="https://og.bunnypunk.io/" target="_blank" className="nav-links w-nav-link">OG stake</a>
                            </div>
                            <div className="div-block-8">
                                <p className="typo-nav-mobile">follow us and join the club</p>
                                <div className="div-block-7">

                                <a href="https://discord.gg/g4sASzxs" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/discord.png" loading="lazy" alt="discord favicon" className="image-social" /></a>
                            <a href="https://t.me/bunnypunkchat" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/Vector.svg" loading="lazy" alt="Telegram link button icon" className="image-social" /></a>
                            <a href="https://twitter.com/bunny_punk_nft" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/Twitter.svg" loading="lazy" alt="Twitter link button icon" className="image-social" /></a>
                             </div>
                            </div>
                            <p className="typo-nav-mobile"><span className="text-span-2">PRIVACY POLICY — TERMS OF USE<br /></span>©2022 - Bunny Punk All rights reserved.</p>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default HeaderComponent