import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import { GlobalContext } from '../../context/GlobalContext';
import CONFIG from './../../abi/config.json';
import contractAbi from './../../abi/abi.json';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useContext, useEffect } from 'react';


const providerOptions = {
  cacheProvider: false,
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        20: 'https://api.elastos.io/eth'
      },
      chainID: 1
    },
  },
};

const styleobj = {
  WebkitTransform: "translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)",
  msTransform: "translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)",
  transform: "translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)",
  opacity: 0
}

const Main = ({ setError, setErrMsg }) => {
  const { account, addAccount, updateProvider } = useContext(GlobalContext);

  const connectWallet = async () => {
    try {
      const web3modal = new Web3Modal({
        providerOptions
      });
      const instance = await web3modal.connect();
      const provider = new ethers.providers.Web3Provider(instance);
      updateProvider(provider)
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      addAccount({ id: address });
      const network = await provider.getNetwork();
      if (network.chainId !== CONFIG.NETWORK_ID) {
        setError(true)
        setErrMsg(`Contract is not deployed on current network. please choose ${CONFIG.NETWORK}`)
      } else {
        setError(false)
        setErrMsg('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', accounts => {
        // addAccount({ id: accounts[0] })
        connectWallet()
      })
      window.ethereum.on('chainChanged', chainId => {
        window.location.reload();
      })
    }
  }, [account]);


  return (
    <section>
<div className="v-application v-theme--imaginaryWorldTheme v-layout v-layout--full-height v-locale--is-ltr is-landing-page">
<div className='v-application__wrap'>
<main className="v-main">
<div className="v-main__wrap">



  <div id="base-full-page-loader2" className="base-full-page-loader">
  <div className="ball-background">
  <div className="ball ball--1"></div>
  <div className="ball ball--2"></div>
  <div className="ball ball--3"></div>
  <div className="ball ball--4"></div>
  <div className="ball ball--5"></div>
  <div className="v-responsive v-img ball ball--blue">
  <div className="v-responsive__sizer" style={{ paddingBottom: "100%"}}></div>
  <img className="v-img__img v-img__img--contain" src="/images/ball-1.50ee057a.png"/></div>
  <div className="v-responsive v-img ball ball--green">
  <div className="v-responsive__sizer" style={{ paddingBottom: "100%"}}></div>
  <img className="v-img__img v-img__img--contain" src="/images/ball-1.50ee057a.png"/></div>
<div className="v-responsive v-img ball ball--purple"><div className="v-responsive__sizer" style={{ paddingBottom: "100%"}}></div>
<img className="v-img__img v-img__img--contain" src="/images/ball-1.50ee057a.png"/></div>
</div>
<div className="loader__content"><div className="v-responsive v-img loader__image">
<div className="v-responsive__sizer" style={{ paddingBottom: "100%"}}></div>
<img className="v-img__img v-img__img--contain" src="/images/preloader.a8bb9b6e.png"/></div>
<div className="loader__logo">
<img className="v-img__img v-img__img--contain inverted-logo" src="/images/bunny-punk-logo.svg"/></div></div>
<div className="loader__text">Loading...</div>
</div>




<main className="main is-unconnected is-open-to-public">
<div className="v-container">
<div className="landing">
  <div className="landing__ball landing__ball--1" tabindex="-1">
  <img className="ball" src="/images/ball-1.50ee057a.png"/></div>
  <div className="landing__ball landing__ball--2" tabindex="-1">
  <img className="ball" src="/images/ball-2.30e35285.png"/></div>



<section className="landing__banner">
<div className="banner__content">
<div className="banner__image is-loaded">
<div className="image">
<img className="v-img__img v-img__img--contain" style={{zIndex: "1"}} src="/images/safari.png"/>
</div>
</div>
<div className="banner__text"><h1 className="banner__title">
<span className="t--white">Stake your</span>
<span className="t--gradient-purple">Bunny Punks</span></h1>
<p className="landing__text">Join our adventure and earn lots of rewards!</p>
<div className="banner__buttons">
<button type="button" className="v-btn v-btn--elevated v-theme--imaginaryWorldTheme v-btn--density-default elevation-0 v-btn--rounded v-btn--size-default v-btn--variant-elevated btn-connect btn-purple-gradient" outlined="">
<span className="v-btn__content" data-no-activator="" onClick={connectWallet}>Stake now</span>
</button>
<a className="v-btn v-btn--elevated v-theme--imaginaryWorldTheme v-btn--density-default v-btn--size-default v-btn--variant-elevated btn-purple-outline" href="https://bunnypunk.io/roadmap" target="_blank">
<span className="v-btn__overlay"></span>
<span className="v-btn__underlay"></span>
<span className="v-btn__content" data-no-activator="">Roadmap</span></a></div></div></div>
</section>


<section className="carousel banner__list" dir="ltr" aria-label="Gallery">
<div className="carousel__viewport">
<ol className="carousel__track">
  <li className="carousel__slide carousel__slide--active carousel__slide--visible" style={{width: "33.3333%", order: "1"}}>
    <div className="stat__item"> 
        <div className="title">714</div>
        <div className="text">BPs staked on our platform</div>
        </div>
        </li>
        <li className="carousel__slide carousel__slide--visible" style={{width: "33.3333%", order: "2"}}>
          <div className="stat__item">

 <div className="title">3+</div>
 <div className="text">Types of rewards to be won</div>
 </div></li>
 <li className="carousel__slide carousel__slide--visible" style={{width: "33.3333%", order: "0" }}>
  <div className="stat__item"> 
<div className="title">1000</div>
<div className="text">Bunny Punks created</div>
</div></li></ol></div>

</section>



<section className="section">
<div className="section__header">
<h2 className="title text--title">Announcement</h2>
</div>

<div className="announcement-carousel">
  <div className="v-slide-group" tabindex="0">
  <div className="v-slide-group__container">
    <div className="v-slide-group__content">
      <div className="announcement-banner">
        <div className="base-banner rounded-2xl d-flex flex-column flex-sm-row-reverse justify-center justify-sm-space-between">
          <div className="base-banner__background">
            <div className="v-responsive v-img">
              <div className="v-responsive__sizer" style={{paddingBottom: "36.8321%"}}></div>
              <img className="v-img__img v-img__img--cover" src="/images/Medium Article Banner.png" /></div></div>
<div className="base-banner__content">
  <div className="v-responsive v-img announcement-ball">
    <div className="v-responsive__sizer" style={{paddingBottom: "100%"}}>
      </div>
      <img className="v-img__img v-img__img--contain" src="/images/Ball.png" />
      </div><div className="v-responsive v-img announcement-microphone">
        <div className="v-responsive__sizer" style={{paddingBottom: "118.367%"}}></div>
        <img className="v-img__img v-img__img--contain" src="/images/ball.png" />
          </div>
          <div className="announcement-content">
            <span className="type">Coming Soon</span>
            <h3 className="my-2 title">How does staking work?</h3>
            <p className="description">Head over to our medium article to learn more.</p>
            <span className="v-btn v-btn--elevated v-theme--imaginaryWorldTheme v-btn--density-default rounded-pill v-btn--size-default v-btn--variant-elevated mt-3 l000btn">          <span className="v-btn__content" data-no-activator="">Coming soon</span>
   </span></div></div></div></div>


   <div className="announcement-banner">
        <div className="base-banner rounded-2xl d-flex flex-column flex-sm-row-reverse justify-center justify-sm-space-between">
          <div className="base-banner__background">
            <div className="v-responsive v-img">
              <div className="v-responsive__sizer" style={{paddingBottom: "36.8321%"}}></div>
              <img className="v-img__img v-img__img--cover rotate-heu" src="/images/Medium Article Banner.png" /></div></div>
<div className="base-banner__content">
  <div className="v-responsive v-img announcement-ball">
    <div className="v-responsive__sizer" style={{paddingBottom: "100%"}}>
      </div>
      <img className="v-img__img v-img__img--contain" src="/images/Ball.png" />
      </div>
      <div className="v-responsive v-img announcement-microphone">
        <div className="v-responsive__sizer" style={{paddingBottom: "118.367%"}}></div>
        <img className="v-img__img v-img__img--contain" src="/images/glow.4898b3a8.png" />
          </div>
          <div className="announcement-content">
            <span className="type">Coming Soon</span>
            <h3 className="my-2 title">How does farming work?</h3>
            <p className="description">Head over to our medium article to learn more.</p>
            <span className="v-btn v-btn--elevated v-theme--imaginaryWorldTheme v-btn--density-default rounded-pill v-btn--size-default v-btn--variant-elevated mt-3 l000btn">          <span className="v-btn__content" data-no-activator="">Coming soon</span>
   </span></div></div></div></div>
   
   
   
   
</div></div></div></div></section>



</div>
</div>
</main>
</div>
</main>
</div>
</div>
    </section>

  );
}

export default Main