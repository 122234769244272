
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useContext, useEffect } from 'react';
import Web3Modal from 'web3modal';
import { GlobalContext } from '../../context/GlobalContext';

const providerOptions = {
    cacheProvider: false,
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            rpc: {
                20: 'https://api.elastos.io/eth'
            },
            chainID: 1
        },
    },
};


const Header = () => {
    const { account, delAccount, updateProvider } = useContext(GlobalContext);

    const onDisconnect = async () => {
        const web3modal = new Web3Modal({
            providerOptions
        });

        const clear = await web3modal.clearCachedProvider();
        window.localStorage.clear();

        delAccount();
        setTimeout(() => {
            window.location.reload();
        }, 1);
    }

    return (
        <>
            <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar-2 w-nav">
                <div className="container nav w-container">
                    <a href="https://stakev2.bunnypunk.io/" className="brand w-nav-brand"><img src="images/bunny-punk-logo.svg" loading="lazy" width="115" alt="bunny punk logo" /></a>
                    <div className="nav-left-holder">
                        <div className="button-nav-big w-inline-block" style={{ cursor: 'pointer' }} onClick={() => onDisconnect()}>
                            <div className="typo-buysun">DISCONNECT WALLET</div>
                        </div>
                        <a href="https://discord.gg/g4sASzxs" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/discord.png" loading="lazy" alt="discord favicon" className="image-social" /></a>
                        <a href="https://t.me/bunnypunkchat" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/telegram.png" loading="lazy" alt="telegram favicon" className="image-social" /></a>
                        <a href="https://twitter.com/bunny_punk_nft" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/Twitter.svg" loading="lazy" alt="twitter favicon" className="image-social" /></a>
                    </div>
                    <div className="nav-middle-holder">
                        <a href="https://bunnypunk.io/" className="nav-links w-nav-link">Home</a>
                        <a href="https://farm.bunnypunk.io/" target="_blank" className="nav-links w-nav-link">Farm</a>
                        <a href="#" target="_blank" className="nav-links w-nav-link nft-soon">NFT Bridge</a>
                        <a href="https://og.bunnypunk.io/" target="_blank" className="nav-links w-nav-link">OG stake</a>
                    </div>
                    <div data-w-id="734b1a8e-b06e-4071-ebd2-3c94b45ac66d" className="menu-button w-nav-button"><img src="images/image---Menu-icon.svg" loading="lazy" alt="" /><img src="images/image-X.svg" loading="lazy" alt="" className="image-13" /></div>
                    <nav role="navigation" className="nav-menu w-nav-menu">
                        <div className="div-block-9">
                            <a href="#" className="button-nav-big-mobile w-inline-block">
                                <div className="typo-buysun">$BUNNY</div>
                            </a>
                            <div className="nav-link-holder-mobile">
                                <a href="https://bunnypunk.io/" className="nav-links w-nav-link">Home</a>
                                <a href="https://farm.bunnypunk.io/" target="_blank" className="nav-links w-nav-link">Farm</a>
                                <a href="#" className="nav-links w-nav-link nft-soon">NFT Bridge</a>
                                <a href="https://og.bunnypunk.io/" target="_blank" className="nav-links w-nav-link">OG stake</a>

                            </div>
                            <div className="div-block-8">
                                <p className="typo-nav-mobile">follow us and join the club</p>
                                <div className="div-block-7">
                                <a href="https://discord.gg/g4sASzxs" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/discord.png" loading="lazy" alt="discord favicon" className="image-social" /></a>
                            <a href="https://t.me/bunnypunkchat" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/Vector.svg" loading="lazy" alt="Telegram link button icon" className="image-social" /></a>
                            <a href="https://twitter.com/bunny_punk_nft" target="_blank" className="button-nav-small middle w-inline-block"><img src="images/Twitter.svg" loading="lazy" alt="Twitter link button icon" className="image-social" /></a>
                                </div>
                            </div>
                            <p className="typo-nav-mobile">Bunny Punk ©2022 - All rights reserved.</p>
                        </div>
                    </nav>
                </div>
            </div>
            <div style={{ display: 'none' }} className="nav-overlay main is-unconnected is-open-to-public staking-unconnected">
            <link rel="stylesheet" href="https://use.typekit.net/lca0jil.css"/>
            <link rel="stylesheet" href="/src/dashboard.css"/>
                <div className="nav-menu-wrapper">
                    <div className="mobile-link-holder">
                        <a href="https://bunnypunk.io/" className="link-mobile-nav">Home</a>
                        <a href="https://farm.bunnypunk.io/" target="_blank" className="link-mobile-nav">Farm</a>
                        <a href="#" target="_blank" className="link-mobile-nav mid nft-soon">NFT Bridge</a>
                        <a href="https://og.bunnypunk.io/" target="_blank" className="link-mobile-nav">OG stake</a>
                        <div className="button-nav-big2 w-inline-block margin-btn unstake-colored" style={{ cursor: 'pointer', margin: '10px 0px', display : 'flex' }} onClick={() => onDisconnect()}>
                            <div className="typo-buysun">DISCONNECT WALLET</div>
                        </div>
                    </div>
                </div>
                <div className="background"></div>
            </div>
            <div className="nav-mobile">
                <a href="https://stakev2.bunnypunk.io/" className="logo-link w-inline-block"><img src="images/bunny-punk-logo.svg" loading="lazy" alt="bunny punk logo" /></a>
                <div data-w-id="d82c271c-c7ca-0d55-ab3e-adddf2873813" className="hamburger-menu"><img src="images/image-X.svg" loading="lazy" alt="" className="image-15" /><img src="images/image---Menu-icon.svg" loading="lazy" alt="" className="image-14" /></div>
            </div>
        </>
    )
}

export default Header